// import React, { useState, useEffect } from 'react';
// import { getSignedUrl } from './aws-config';
// import { Avatar } from '@mui/material';

// const S3Image = ({ imageKey }) => {
//     console.log(imageKey)
//   const [imageUrl, setImageUrl] = useState('');

//   useEffect(() => {
//     const fetchImageUrl = async () => {
//       try {
//         console.log('public/'+ imageKey);
//         const url = await getSignedUrl('public/'+ imageKey);
//         console.log(url);
//         setImageUrl(url);
//       } catch (error) {
//         console.error('Error fetching image URL:', error); 
//       }
//     };   

//     fetchImageUrl();
//   }, [imageKey]);

//   return imageUrl ? <Avatar src={imageUrl} alt="S3 Image" /> : <p>Loading...</p>;
// };

// export default S3Image;




import React, { useState, useEffect } from 'react';
import { getSignedS3Url } from './s3Utils';

const S3Image = ({ imageKey, altText }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchSignedUrl() {
      try {
        console.log('public/'+ imageKey);
        const url = await getSignedS3Url('public/'+ imageKey);
        console.log(url);
        if (url) {
          setImageUrl(url);
        } else {
          setError('Failed to generate signed URL');
        }
      } catch (err) {
        setError('Error fetching image URL');
        console.error(err);
      }
    }

    fetchSignedUrl();
  }, [imageKey]);

  if (error) {
    return <div aria-live="polite">Error: {error}</div>;
  }

  return imageUrl ? (
    <img 
      src={imageUrl} 
      alt={altText} 
      onError={() => setError('Failed to load image')}
    />
  ) : (
    <div aria-live="polite">Loading...</div>
  );
};

export default S3Image;











