import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid } from '@mui/material';
// import { QrReader } from 'react-qr-reader'; // Example QR reader, install with 'npm install react-qr-reader'
import CircularProgress from '@mui/material/CircularProgress';
import Logo from "../images/icons/logo.svg";
import { QrCode } from 'lucide-react';

const OHSM = () => {
  const [qrData, setQrData] = React.useState(null);
  const [scanError, setScanError] = React.useState(false);
  const [isScanning, setIsScanning] = React.useState(true);

  const handleScan = (data) => {
    if (data) {
      setQrData(data);
      setIsScanning(false);
      // Perform any action after QR code scan here
    }
  };

  const handleError = (error) => {
    setScanError(true);
    console.error(error);
  };

  const handleRetry = () => {
    setScanError(false);
    setIsScanning(true);
  };

  return (
    <Dialog
      open={true}
      disableEscapeKeyDown
      disableBackdropClick
      // onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: 20,
          padding: "20px",
          width: "90%",
          maxWidth: "400px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold", color: "#E7319DFF" }}>
        OHSM POS
      </DialogTitle>
      <Box sx={{ borderBottom: '1px solid #E5E7EB', margin: '3px 0' }} />
      <DialogContent>
        <Typography variant="subtitle1" sx={{ textAlign: "center", marginBottom: "16px", fontWeight: "bold" }}>
          Please Scan QR Code or Ask property to share the link
        </Typography>
        {/* {isScanning && !scanError ? (
          <QrCode
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
          />
        ) : scanError ? (
          <Typography variant="body2" sx={{ color: "red", textAlign: "center" }}>
            Error scanning QR code. Please try again.
          </Typography>
        ) : (
          <Typography variant="body2" sx={{ color: "green", textAlign: "center" }}>
            QR Code Scanned Successfully!
          </Typography>
        )} */}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", paddingTop: 0.5 }}>
        {scanError && (
          <Button variant="outlined" fullWidth onClick={handleRetry} sx={{ color: "#2FA551", fontWeight: 'bold' }}>
            Retry
          </Button>
        )}
        {/* <Button
          variant="outlined"
          fullWidth
          onClick={handleClose}
          sx={{ borderColor: "#2FA551", color: "#2FA551", fontWeight: 'bold' }}
        >
          Cancel
        </Button> */}
      </DialogActions>
      <Typography
        variant="caption"
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          marginTop: "16px",
          color: "#9E9E9E",
        }}
      >
        Powered By:{" "}
        <img
          alt="logo"
          style={{ marginLeft: 4 }}
          src={Logo}
        />
      </Typography>
    </Dialog>
  );
};

export default OHSM;
