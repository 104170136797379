import { Box, Avatar, Typography } from '@mui/material';
import NoResultsFound from '../images/icons/no-results.svg';


 const RenderNoResults = ({name}) => (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        minHeight: '50vh',
        padding: 3
      }}
    >
      <Avatar 
        src={NoResultsFound} 
        sx={{ 
          width: 120, 
          height: 120, 
          marginBottom: 2 
        }} 
      />
      <Typography 
        variant="h6" 
        sx={{ 
          color: 'text.secondary',
          textAlign: 'center',
          marginBottom: 1
        }}
      >
      { `The search could not find the ${name}`}
      </Typography>
      <Typography 
        variant="body2" 
        sx={{ 
          color: 'text.secondary',
          textAlign: 'center'
        }}
      >
        Please go back to the homepage
      </Typography>
    </Box>
  );


  export default RenderNoResults