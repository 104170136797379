const awsconfig = {
    aws_project_region: "us-east-1", // e.g., "ap-south-1"
    aws_cognito_identity_pool_id: "us-east-1:9cd6fc20-d424-4299-b76a-54844a2f6cc5", // e.g., "ap-south-1:xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
    aws_cognito_region: "us-east-1", // e.g., "ap-south-1"
    aws_user_pools_id: "us-east-1_7h98GSvpS", // e.g., "ap-south-1_xxxxxxxxx"
    aws_user_pools_web_client_id: "4pjrf9bt9ampuorvphacq0t2tu", // e.g., "xxxxxxxxxxxxxxxxxxxxxxxxxx"
    oauth: {
        "ohsm54879e63": {
          "service": "Cognito",
          "providerPlugin": "awscloudformation",
          "dependsOn": [],
          "customAuth": false,
          "frontendAuthConfig": {
            "socialProviders": [],
            "usernameAttributes": [],
            "signupAttributes": [
              "EMAIL"
            ],
            "passwordProtectionSettings": {
              "passwordPolicyMinLength": 8,
              "passwordPolicyCharacters": []
            },
            "mfaConfiguration": "OFF",
            "mfaTypes": [
              "SMS"
            ],
            "verificationMechanisms": [
              "EMAIL"
            ]
          },
          "providerMetadata": {
            "s3TemplateURL": "https://s3.amazonaws.com/amplify-ohsm-dev-151750-deployment/amplify-cfn-templates/auth/ohsm54879e63-cloudformation-template.json",
            "logicalId": "authohsm54879e63"
          },
          "lastPushTimeStamp": "2023-09-21T09:53:44.647Z",
          "output": {
            "UserPoolId": "us-east-1_7h98GSvpS",
            "AppClientIDWeb": "4pjrf9bt9ampuorvphacq0t2tu",
            "AppClientID": "5brovjibs09fltba2ts8o7hqbg",
            "IdentityPoolId": "us-east-1:9cd6fc20-d424-4299-b76a-54844a2f6cc5",
            "UserPoolArn": "arn:aws:cognito-idp:us-east-1:443986756837:userpool/us-east-1_7h98GSvpS",
            "IdentityPoolName": "ohsm54879e63_identitypool_54879e63__dev",
            "UserPoolName": "ohsm54879e63_userpool_54879e63"
          },
          "lastPushDirHash": "4eIQHNffxxUh7chmI0OqUYD4gVc="
        }
      },

      Storage: {
        AWSS3: {
            bucket: "ohsmbucket151750-dev", // e.g., "your-s3-bucket-name"
            region: "us-east-1", // e.g., "us-east-1"
        },
    },

    // OPTIONAL - Amazon S3 bucket configuration
    aws_user_files_s3_bucket: "ohsmbucket151750-dev", // e.g., "yourbucketname"
    aws_user_files_s3_bucket_region: "us-east-1", // e.g., "ap-south-1"

    // OPTIONAL - AWS AppSync API configuration
    aws_appsync_graphqlEndpoint: "YOUR_APPSYNC_ENDPOINT_URL", // e.g., "https://xxxxxxxxxxxxxxxxxxxxxxxxxxxx.appsync-api.ap-south-1.amazonaws.com/graphql"
    aws_appsync_region: "YOUR_APPSYNC_REGION", // e.g., "ap-south-1"
    aws_appsync_authenticationType: "API_KEY", // or "AMAZON_COGNITO_USER_POOLS" or "AWS_IAM"

    // OPTIONAL - Amazon Pinpoint configuration
    aws_mobile_analytics_app_id: "YOUR_PINPOINT_APP_ID", // e.g., "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
    aws_mobile_analytics_app_region: "YOUR_PINPOINT_REGION", // e.g., "ap-south-1"
};

export default awsconfig;
