import { useState } from 'react';
import { Card, CardContent, Typography, Button, Dialog, DialogTitle, 
         DialogContent, Divider, Box, Paper, IconButton, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { KeyboardArrowRight, FiberManualRecord } from '@mui/icons-material';
import { format, parseISO, addHours, addMinutes } from 'date-fns';
import { getHostelData } from '../api/services/FetchNodeService';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2.5),
  boxShadow: theme.shadows[8],
  backgroundColor: 'white',
}));

const formatDate = (date) => {
  if (!(date instanceof Date)) {
    date = new Date(date); // Convert to Date object if it's not already
  }

  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
};

const InfoLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  fontWeight: 500,
  fontSize: '0.875rem',
  width: 85,
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 500,
  fontSize: '0.875rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

const ItemDot = styled(FiberManualRecord)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: '0.5rem',
  marginTop: theme.spacing(0.75),
}));

const formatTime = (dateStr) => {
  try {
    // Parse the UTC date string
    const date = new Date(dateStr);

    // Convert to IST by adding 5 hours and 30 minutes (IST offset from UTC)
    date.setHours(date.getUTCHours() + 5);
    date.setMinutes(date.getUTCMinutes() + 30);

    // Extract hours, minutes, and period (AM/PM)
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12 || 12; // 0 becomes 12 for 12-hour format
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes; // Pad single-digit minutes with a leading zero

    // Manually format to 'hh:mm AM/PM'
    return `${hours}:${formattedMinutes} ${ampm}`;
  } catch (e) {
    console.error("Error formatting date:", e);
    return dateStr; // Return the original string if parsing fails
  }
};


const id = localStorage.getItem('hostel_id');
const phone = localStorage.getItem('guestPhone');

const orderHistorys = async () => {
  try{
    const res = await getHostelData(`order-history-foodMenu-hostel/${id}/${phone}`);
    console.log(res);
    console.log(res.data);
    return res;
  }catch(e){
    console.log(e);
  }
}


const ItemList = ({ items, compact = true }) => {
    console.log(items);
  const displayItems = compact ? items.slice(0, 5) : items;

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography color="warning.main" fontWeight={500} fontSize="0.75rem">
          Items
        </Typography>
        <Typography color="text.secondary" fontSize="0.75rem">
          {items.length} items
        </Typography>
      </Box>
      {displayItems.map((item, index) => (
        <Box key={index} display="flex" alignItems="flex-start" py={0.5}>
          <ItemDot />
          <Box ml={1} flex={1}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography color="text.secondary" fontSize="0.75rem" noWrap sx={{ flex: 1 , textAlign: 'left' }}>
                {item.name}
              </Typography>
              <Chip
                label={`x${item.quantity}`}
                size="small"
                sx={{
                  bgcolor: 'success.light',
                  color: 'success.dark',
                  ml: 1,
                  height: 20,
                }}
              />
            </Box>
            {item.add_on_checklist?.length > 0 && (
              <Typography
                color="text.secondary"
                fontSize="0.625rem"
                sx={{ opacity: 0.8 , textAlign: 'left' }}
                noWrap
              >
                {item.add_on_checklist.map(addon => addon.name).join(', ')}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const OrderDetailsDialog = ({ open, onClose, order, guest , phone }) => {
  // console.log(order);
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: { borderRadius: 3 }
      }}
    >
      <DialogTitle>
        <Typography variant="h6" align="center" fontWeight={600}>
          Order Details
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box py={2}>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <InfoLabel>Guest Name:</InfoLabel>
            <InfoValue>{guest}</InfoValue>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <InfoLabel>Phone Number:</InfoLabel>
            <InfoValue>{phone}</InfoValue>
          </Box>
          {order.description && (
            <Box display="flex" justifyContent="space-between" mb={1}>
              <InfoLabel>Serving Location:</InfoLabel>
              <InfoValue>{order.description}</InfoValue>
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" mb={1}>
            <InfoLabel> Order Time:</InfoLabel>
            <Box textAlign="right">
              <InfoValue component="span">{order.entryBy}</InfoValue>
              <Typography component="span" color="text.disabled" ml={1}>
                {formatTime(order.createdAt)}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <InfoLabel>Order Date:</InfoLabel>
            <InfoValue>{formatDate(order.createdAt)}</InfoValue>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <InfoLabel>Total Amount:</InfoLabel>
            <InfoValue>₹{ order.total_amount}</InfoValue>
          </Box>
          <Divider sx={{ my: 2 }} />
          <ItemList items={order.billed_item} compact={false} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};


const OrderCard = ({ order, guest, phone }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const currDate = new Date();
  currDate.setHours(0, 0, 0, 0);
  
  const orderDate = new Date(order.date);
  const isInactive = orderDate < currDate || orderDate > currDate;

  return (
    <>
      <StyledCard>
        <CardContent>
          <Box display="flex">
            <Box flex sx={{textAlign: 'left'}}>
            <Box display="flex" alignItems="center" mb={1}>
            <InfoLabel sx={{ minWidth: 100 }}>Guest Name:</InfoLabel>
            <InfoValue>{guest}</InfoValue>
          </Box>
          {order.description && (
            <Box display="flex" alignItems="center" mb={1}>
              <InfoLabel sx={{ minWidth: 100 }}>Location:</InfoLabel>
              <InfoValue>{order.description}</InfoValue>
            </Box>
          )}
          <Box display="flex" alignItems="center" mb={1}>
            <InfoLabel sx={{ minWidth: 100 }}>Order Time:</InfoLabel>
            <InfoValue>{formatTime(order.createdAt)}</InfoValue>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <InfoLabel sx={{ minWidth: 100 }}>Order Date:</InfoLabel>
            <InfoValue>{formatDate(order.createdAt)}</InfoValue>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <InfoLabel sx={{ minWidth: 100 }}>Total Amount:</InfoLabel>
            <InfoValue>₹ {order.total_amount}</InfoValue>
          </Box>

            </Box>
          </Box>
          <Divider sx={{ my: 3 }} />
          <ItemList items={order.billed_item} />
          <Button
            fullWidth
            onClick={() => setDetailsOpen(true)}
            sx={{
              color: 'success.main',
              mt: 1,
              '&:hover': { bgcolor: 'success.light' },
            }}
            endIcon={<KeyboardArrowRight />}
          >
            View More
          </Button>
        </CardContent>
      </StyledCard>
      <OrderDetailsDialog
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        order={order}
        guest={guest}
        phone={phone}
      />
    </>
  );
};

export default OrderCard;
