import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID || 'AKIAWOX5MITS5ZMAPHEH',
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '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',
  },
});

export async function getSignedS3Url(key) {
  const command = new GetObjectCommand({
    Bucket: process.env.REACT_APP_S3_BUCKET || "ohsmbucket151750-dev",
    Key: key,
  });

  try {
    const signedUrl = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
    return signedUrl;
  } catch (error) {
    console.error("Error generating signed URL:", error);
    return null;
  }
}