import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import logo from '../images/icons/logo.svg'

const Footer = () => {
  return (
    <Box 
      component="footer"
      sx={{
        padding: '8px 16px',
        backgroundColor: '#ffffff',
        textAlign: 'right',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box', // Ensures padding doesn't affect the width
        zIndex: 1000, // Ensure it stays on top of other content
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Typography variant="body2" sx={{ display: 'inline-block', marginRight: '4px', color: '#5f6368', fontSize: '12px' }}>
        Powered by:
      </Typography>
      <Link href="https://ohsm.in" target="_blank" rel="noopener" sx={{ color: '#ff007f', textDecoration: 'none', fontWeight: 'bold', fontSize: '14px' , marginBottom:-0.6  }}>
        <img src={logo} alt="Logo" style={{ width: '50px', height: 'auto'}} />
      </Link>
    </Box>
  );
};

export default Footer;
