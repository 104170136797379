import React, { useEffect, useRef, useState ,  } from 'react';
import Swal from "sweetalert2";
import expIcon from "../images/icons/exp_icon.svg";
import debounce from 'lodash.debounce';
import { 
  Box, 
  Grid, 
  Typography, 
  Avatar, 
  IconButton, 
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  CircularProgress,
  Paper
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrderItems, setCategories } from '../Redux/store';
import { getHostelData } from '../api/services/FetchNodeService';
import { navigateWithHostelParams } from '../components/NavigateWithHostelParams';
import FoodLoader from '../Loader/loading';
import OrderSummary from '../components/OrderSummary';
import RenderNoResults from '../components/RenderNoResults';

// Import your icons
import categoryFoodIcon from '../images/icons/categoryFoodIcon.svg';
import backButton from '../images/icons/backIcon.svg';
import fnbIcon from "../images/icons/fnb_icon.svg";
import SearchImage from "../images/icons/search.svg";
import foodIcon from "../images/icons/food.svg";
import S3ImageAmplify from '../config/S3ImageAmplify';
import IconHandler from '../components/IconHandler';

const CategoryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const orderItems = useSelector((state) => state.food.orderItems);
  const reduxCategories = useSelector(state => state.food.categories);
  console.log('reduxCategories', reduxCategories);

  const { label, categories: locationCategories } = location.state || {};
  const originalLabel = label || localStorage.getItem('LABEL');

  const [loading, setLoading] = useState(false);
  const [icon, setIcons] = useState(foodIcon);
  const [searchQuery, setSearchQuery] = useState("");
  const [fetchedData, setFetchedData] = useState([]);
  const [items, setItems] = useState([]);
  const [searchResults, setSearchResults] = useState({ categories: [], items: [] });
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [categories, setCategoriesState] = useState(locationCategories || reduxCategories);
  const [isOrderSummaryVisible, setIsOrderSummaryVisible] = useState(orderItems.length > 0);
  const [searchLoading, setSearchLoading] = useState(false);
  const [parentSubCat , setParentSubCat] = useState(originalLabel === 'Food & Beverage' ? 'fnb' : 'exp');
  const [debouncedQuery, setDebouncedQuery] = useState('');


  const categoryIcons = {
    'Continental': categoryFoodIcon,
    'Thali\'s': categoryFoodIcon,
    'Tandoor': categoryFoodIcon,
    'Breakfast': categoryFoodIcon,
    'Hot & Cold Beverages': categoryFoodIcon,
    'Food & Beverage': fnbIcon,
    'Experiences & Merchandise': expIcon,
  };

  const handleCloseOrderSummary = () => {
    setIsOrderSummaryVisible(false);
  };


  const fetchItems = async () => {
     try{
      const hostel_id = localStorage.getItem('hostel_id');

      const apiEndpoint = `account/item-hostel/${hostel_id}/${[parentSubCat]}`;
      console.log('API Endpoint:', apiEndpoint);

      const response = await getHostelData(apiEndpoint, navigate);
      console.log('response', response);
      if (response?.data?.length) {
        setItems(response.data);
      }

     }catch(err){
      console.error(err);
      }
    }

    useEffect(() => {
      fetchItems();
    }, []);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const hostel_id = localStorage.getItem('hostel_id');
      if (hostel_id) {
        console.log(`account/sub-category/hostel/${hostel_id}/parent_sub_cat/${parentSubCat}`);
        const response = await getHostelData(`account/sub-category/hostel/${hostel_id}/parent_sub_cat/${parentSubCat}`, navigate);
        console.log('response', response);
        const filteredCategories = response.data.filter(category => 
          category.total_inventory > 0 && 
          category.available === true
        );
        console.log('filteredCategories', filteredCategories);
        dispatch(setCategories(filteredCategories));
        setCategoriesState(filteredCategories);
        setCategories(filteredCategories);
      }
    } catch (error) {
      console.error('Failed to fetch categories:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!categories || categories.length === 0) {
      fetchCategories();
    }
  }, [categories]);

  const filteredItems = categories?.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  
  

 

  const handleCategoryClick = async (category) => {
    setLoading(true);
    try {
      const data = await fetchCategoryItem(category.id, category.parent_sub_category);
      setFetchedData(data); 
      setIsSearchDialogOpen(false);
      navigateWithHostelParams(navigate, '/food-item', { state: { datas: data, category: category } });
    } catch (error) {
      console.error('Error fetching category items:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleCategoryClickByItem = async (item) => {
    setLoading(true);
    try {
      const data = await fetchCategoryItem(item.category_id   , item.sub_category_id);
      const category = await getHostelData(`account/account-category/${item.category_id}`, navigate);
      setFetchedData(data); 
      setIsSearchDialogOpen(false);
      navigateWithHostelParams(navigate, '/food-item', { state: { datas: data, category: category } });
    } catch (error) {
      console.error('Error fetching category items:', error);
    } finally {
      setLoading(false);
    }
  };

  // const fetchCategoryItems = async (categoryId) => {
  //   try {
  //     const response = await getHostelData(`account/fnb-item/category/${categoryId}`, navigate);
  //     return response.data.filter(item => item.in_stock === true);
  //   } catch (error) {
  //     console.error('Failed to fetch category items:', error);
  //     throw error;
  //   }
  // };

  const fetchCategoryItem = async (categoryId, parent_sub_category) => {
    try {
      let response;
      if (parent_sub_category === 'fnb') {
        response = await getHostelData(`account/fnb-item/sub-category/${categoryId}` , navigate);
      } else if (parent_sub_category === 'exp') {
        response = await getHostelData(`account/exp-item/sub-category/${categoryId}` , navigate);
      }
      if (response && response.data) {
        return response.data; // Return the fetched data
      } else {
        throw new Error('No data returned from the API');
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
      throw error; // Rethrow the error to handle it in the click event
    }
  };

  // const handleBackClick = () => {
  //   dispatch(clearOrderItems());
  //   navigate(-1);
  // };

  const handleBackClick = () => {
    if (orderItems.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "Your cart have items. Do you want to discard your cart?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, discard",
        cancelButtonText: "No, keep items",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(clearOrderItems()); // Clear the order items
          navigate(-1); // Navigate back
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // navigate(-1); // Navigate back without clearing
        }
      });
    } else {
      navigate(-1); // Navigate back directly if cart is empty
    }
  };

  const SearchDialog = () => {  
    const [searchQuery, setSearchQuery] = useState("");

    const filteredItemsIn = items?.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const filteredcategorysIn = categories?.filter((category) =>
      category.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

return(
    <Dialog
      fullScreen
      open={isSearchDialogOpen}
      onClose={() => setIsSearchDialogOpen(false)}
      PaperProps={{
        style: {
          backgroundColor: '#f5f5f5'
        }
      }}
    >
      <Box sx={{ backgroundColor: '#fff', padding: 2, position: 'sticky', top: 0, zIndex: 1 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <IconButton onClick={() => setIsSearchDialogOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search categories and items..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: 'gray', mr: 1 }} />
              }}
              autoFocus
            />
          </Grid>
        </Grid>
      </Box>

      <DialogContent sx={{ padding: 2 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" padding={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {filteredcategorysIn?.length > 0 && (
              <>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                  Categories
                </Typography>
                <List>
                  {filteredcategorysIn?.map((category) => (
                    <Paper 
                      key={category.id} 
                      elevation={1} 
                      sx={{ mb: 2, borderRadius: 2 }}
                    >
                      <ListItem 
                        button 
                        onClick={() => handleCategoryClick(category)}
                      >
                        <ListItemAvatar>
                          <Avatar src={categoryIcons[originalLabel] } variant="rounded" />
                        </ListItemAvatar>
                        <ListItemText
                          primary={category.name}
                          secondary={`${category.total_inventory} items`}
                        />
                      </ListItem>
                    </Paper>
                  ))}
                </List>
              </>
            )}
        

            {filteredItemsIn?.length > 0 && (
              <>
                <Typography variant="h6" sx={{ mb: 2, mt: 3, fontWeight: 'bold' }}>
                  Items
                </Typography>
                <List>
                  {filteredItemsIn?.map((item) => {
                     const useLocalImage = item.icon_name && item.icon_name.includes('ic');
                    return(
                    <Paper 
                      key={item.id} 
                      elevation={1} 
                      sx={{ mb: 2, borderRadius: 2 , cursor: 'pointer' }}
                      onClick={() => handleCategoryClickByItem(item)} // Call handleCategoryClick with item data
                    >
                      <ListItem>
                        <ListItemAvatar>
                 

{useLocalImage ? (
      
        <IconHandler 
        iconPath={item.icon_name} 
        fallbackIcon={foodIcon} 
        altText={item.name}
       
         />
      ) : (
        <S3ImageAmplify
          imageKey={item.icon_name}
          grpUserId={item.user_id}
          altText={item.name}
      
        />
      )}
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.name}
                          secondary={
                            <>
                              <Typography component="span" variant="body2">
                                {item.description}
                              </Typography>
                              <br />
                              <Typography component="span" variant="body2" color="primary">
                                ₹{item.rate}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    </Paper>
                  )})}
                </List>
              </>
            )}

            {searchQuery && 
             !searchLoading && 
             searchResults.categories.length === 0 && 
             searchResults.items.length === 0 && (
              <Box sx={{ textAlign: 'center', py: 4 }}>
                <Typography color="text.secondary">
                  No results found for "{searchQuery}"
                </Typography>
              </Box>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );};

  return (
    <>
      <Box sx={{ 
        backgroundColor: '#f5f5f5', 
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column' 
      }}>
        <Box sx={{
          backgroundColor: '#ffffff',
          boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.4)',
          zIndex: 1000,
          paddingTop: 2,
          position: 'fixed',
          top: 0,
          width: '100%',
        }}>
          <Grid container alignItems="center" justifyContent="space-between" sx={{
            marginBottom: '14px',
            paddingRight: '24px',
            paddingLeft: '24px',
          }}>
            <IconButton onClick={handleBackClick}>
              <Avatar src={backButton} alt="Back" sx={{ width: 24, height: 24 }} />
            </IconButton>
            <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: 18 }}>
              {originalLabel}
            </Typography>
            <IconButton onClick={() => setIsSearchDialogOpen(true)}>
              <Avatar src={SearchImage} alt="Search" sx={{ width: 24, height: 24 }} />
            </IconButton>
          </Grid>
        </Box>

        <Box sx={{ 
          flexGrow: 1, 
          overflow: 'auto',
          marginTop: '72px',
          marginBottom: '37px'
        }}>
          {loading ? (
            <FoodLoader loading={loading} />
          ) : categories?.length > 0 ? (
            <Grid container spacing={2} sx={{ 
              padding: '24px',
              paddingBottom: orderItems.length ? '72px' : '24px'
            }}>
              {categories.map((category) => (
                <Grid item xs={6} key={category.id}>
                  <Box
                    onClick={() => handleCategoryClick(category)}
                    sx={{
                      backgroundColor: '#fff',
                      padding: '16px',
                      borderRadius: '12px',
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                      cursor: 'pointer',
                      textAlign: 'center',
                      minHeight: '150px',
                      margin: 1,
                    }}
                  >
                    <Avatar
                      src={
                        categoryIcons[originalLabel] 
                      }
                      sx={{ width: 56, height: 56, margin: '0 auto 8px auto' }}
                      variant="rounded"
                    />
                    <Typography variant="h6" sx={{ 
                      fontWeight: 'bold',
                      fontSize: 16,
                      margin: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      height: '48px',
                      textAlign: 'center',
                    }}>
                      {category.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#6c757d', fontSize: 14 }}>
                      Total Items: {category.total_inventory}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <RenderNoResults name="Category" />
          )}
        </Box>
      </Box>

      <SearchDialog />

      {isOrderSummaryVisible && (
        <Box sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#fff",
          boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid #e0e0e0",
          zIndex: 1000,
          marginTop: "auto",
          margin: 0,
          padding: 0
        }}>
          <OrderSummary
            orderItems={orderItems}
            onClose={handleCloseOrderSummary}
            icon={icon}
          />
        </Box>
      )}
    </>
  );
};

export default CategoryList;