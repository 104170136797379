import React from 'react';
import baverageIcon from "../images/icons/baverage.svg";
import trackingIcon from "../images/icons/trackingIcon.svg";
import foodIcon from "../images/icons/food.svg";
import merchandise from "../images/icons/Merchandies.svg";
import medition from "../images/icons/medition.svg";
import camping from "../images/icons/camping.svg";
import waterSports from "../images/icons/WaterSports.svg";
import { Avatar } from '@mui/material';


const IconHandler = ({ iconPath, fallbackIcon,altText , style }) => {
  // Helper function to get the correct icon path
  const getIconPath = (path) => {
    if (!path) return fallbackIcon;

    // If the path starts with 'assets/icons/'
    if (path.startsWith('assets/icons/')) {
      // Extract the icon name (e.g., 'ic_beverage_option.svg')
      const iconName = path.split('/').pop();
      
      // Import all your local icons here
      const iconMap = {
        'ic_beverage_option.svg': baverageIcon,
        'ic_food_option.svg': foodIcon,
        'ic_trekking.svg': trackingIcon,
        'ic_merchandise.svg': merchandise,
        'ic_meditation.svg': medition,
        'ic_camping.svg': camping,
        'ic_water_sports.svg': waterSports,
    
        // Add more mappings as needed
      };

      // Return the local path if it exists in the map
      return iconMap[iconName] || fallbackIcon;
    }

    // Return the original path if it doesn't match the assets/icons pattern
    return path;
  };

  return (
    <Avatar 
    variant="rounded"
      src={getIconPath(iconPath)} 
      alt={altText}
      style={style}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = fallbackIcon;
      }}
    />
  );
};

export default IconHandler;