import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import { getHostelData, postHostelData, putHostelData } from "../api/services/FetchNodeService";
import { useParams, useNavigate , useLocation } from 'react-router-dom';
import Logo from "../images/icons/logo.svg";
import { navigateWithHostelParams } from "../components/NavigateWithHostelParams";

const Login = () => {
  const [open, setOpen] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [nameNotFound, setNameNotFound] = useState(false);
  const [userId , setUserId] = useState("");
  const [guestId , setGuestId] = useState("");
  const [guestFolioId, setGuestFolioId] = useState("");
  const [guestFolio , setGuestFolio] = useState(false);
  const [categoryId , setCategoryId] = useState("");
  const { hostel_name, hostel_id } = useParams();

 
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    message: '',
    severity: 'info'
  });

  const navigate = useNavigate();
  const location = useLocation();

  

    // Handle alert close
    const handleAlertClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setAlertInfo(prev => ({ ...prev, open: false }));
    };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return; // Prevent closing for these reasons
    }
    setOpen(false);
  };

  const handlePhoneNumberChange = async (event) => {
    const phone = event.target.value;
    setPhoneNumber(phone);

    if (phone.length === 10) {
      setLoading(true);
      setNameNotFound(false);
      await fetchGuestData(phone);
      setLoading(false);
    }
  };

 

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const isPhoneNumberValid = phoneNumber.length === 10;
  const isFormValid = isPhoneNumberValid && name.trim().length > 0;


  // Function to check if today's date is before or after specific dates
  const compareDates = (checkInDate, checkOutDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight for accurate date comparison
  
    // Convert 'DD-MM-YYYY' to a JavaScript Date object
    const [inDay, inMonth, inYear] = checkInDate.split('-');
    const checkIn = new Date(`${inYear}-${inMonth}-${inDay}`);
  
    const [outDay, outMonth, outYear] = checkOutDate.split('-');
    const checkOut = new Date(`${outYear}-${outMonth}-${outDay}`);
  
    // Create an array of all dates from check-in to check-out
    const dateArray = [];
    for (let dt = new Date(checkIn); dt <= checkOut; dt.setDate(dt.getDate() + 1)) {
      dateArray.push(new Date(dt));
    }
  
    // Check if today's date is in the dateArray
    return !dateArray.some(date => 
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

// // Example guest data
// const guest = {
//   check_in_date: '08-10-2024',
//   check_out_date: '10-10-2024',
// };

// Compare the dates
// compareDates(guest.check_in_date, guest.check_out_date);


  const fetchGuestData = async (phone) => {
    try {
      const response = await getHostelData(`account/guest/hostel/${hostel_id}/phone/${phone}`);
      // console.log(response);
      // console.log(response.data);
      // console.log(response.data.length);

      if (response && response.data && response.data.length > 0) {
        // const sortedGuests = response.data.sort((a, b) => 
        //   new Date(b.check_in_date).getTime() - new Date(a.check_in_date).getTime()
        // );
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
        
          console.log(today);

           // Helper function to parse date strings like "31 Oct 2024"
      const parseDate = (dateString) => {
        const parsedDate = new Date(dateString);
        if (isNaN(parsedDate)) {
          throw new Error(`Invalid date format: ${dateString}`);
        }
        return parsedDate;
      };

      console.log(parseDate);

      // Filter current bookings (today is between check-in and check-out dates)
      const currentBookings = response.data.filter(guest => {
        const checkIn = parseDate(guest.check_in_date).getTime();
              console.log(checkIn);

        const checkOut = parseDate(guest.check_out_date).getTime();
        console.log(checkIn);
        console.log(guest.bill_clear);
        return today >= checkIn && today <= checkOut && (guest.bill_clear == false || guest.bill_clear == undefined);
      });

      console.log(currentBookings);

    

      let guest;
      if (currentBookings.length > 0) {
        // Sort current bookings by check-in date (ascending)
        guest = currentBookings.sort((a, b) =>
          new Date(a.check_in_date).getTime() - new Date(b.check_in_date).getTime()
        )[0];
        console.log(guest);
      } 
      else {
        // If no current bookings, sort all by check-out date (latest first)
        const sortedGuests = response.data.sort((a, b) => {
          const checkOutComparison =
            new Date(b.check_out_date).getTime() - new Date(a.check_out_date).getTime();
          if (checkOutComparison === 0) {
            // If check-out dates are the same, sort by check-in date
            return new Date(b.check_in_date).getTime() - new Date(a.check_in_date).getTime();
          }
          return checkOutComparison;
        });
        guest = sortedGuests[0];
        console.log(guest);
      }

        console.log(guest);
        console.log(guest.name);
        console.log(guest.id);

        setName(guest.name);
        localStorage.setItem("guestName", guest.name);
        localStorage.setItem("guestId" , guest.id);
        localStorage.setItem("currentFolioId" ,guest.current_folio_id)
        localStorage.setItem("hostel_id", hostel_id);
        localStorage.setItem("hostel_name", hostel_name);
        localStorage.setItem("guestPhone", guest.phone);

        setGuestId(guest.id);
        setGuestFolioId(guest.current_folio_id);
        setUserId(guest.user_id);
        localStorage.setItem("guestStatus" , guest.guest_type);
        console.log(guest.guest_type);
        // console.log(guest.bill_clear === true);   
        // console.log(compareDates(guest.check_in_date , guest.check_out_date));

        if(guest.bill_clear === true || compareDates(guest.check_in_date , guest.check_out_date) === true){
            setGuestFolio(true);
          console.log("hello world");
        }else{
         const updateResponse = await putHostelData(`account/guest-login/${guest.id}` , {
            id: guest.id,
          })
          console.log(response);
          console.log(updateResponse);

          // console.log(response.data);
          // console.log(response.data[0].auth_token);
          localStorage.setItem("TOKEN" , updateResponse.data.auth_token);
          const loginTime = new Date().getTime(); // Current time in milliseconds
          const loginDate = new Date(); // Current time in milliseconds
          console.log(loginDate);
          localStorage.setItem("loginTime", loginTime); // Store login time
          // Update authentication state
        // onLoginSuccess();
        }
      } else {
        // console.log("hello world 2");
        setName("");
        setNameNotFound(true);
        localStorage.removeItem("guestName");
      }
    } catch (error) {
      // console.error("Error fetching guest data:", error);
      setName("");
      setNameNotFound(true);
      localStorage.removeItem("guestName");
    }
  };

  const fetchHostelData = async () => {
      try{

        const response = await getHostelData(`hostel/food-menu/${hostel_id}`)

        // if(response && response.data){
          console.log(response);
          const hostel = response.data;
          // const categoryId = response.categoryId;
          setCategoryId(response.categoryId);
          // console.log(response.categoryId);

          // console.log(hostel);
          // console.log(hostel.user_id);
          localStorage.setItem("hostel_id", hostel_id);
          localStorage.setItem("user_id" , hostel.user_id);
          localStorage.setItem("categoryId" , response.categoryId);
          setUserId(hostel.user_id);
        }catch(e){
        console.log(e);
      }
      
  }

  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
  };
  
  // const todayDate = formatDate(new Date());
  // console.log(todayDate); // Output: '07 Oct 2024' (for example)
  

  const handleSubmit = async () => {
    setSubmitLoading(true); // Set loading to true
    localStorage.setItem("hostel_name", hostel_name);
      // Post data if name was not found and entered manually
      const today = formatDate(new Date());
      const checkInDate = today;
      const checkOutDate = today;
      let response;
      let guestFolioResponse;
      let updateGuest;
      if (nameNotFound && name.trim().length > 0 || guestFolio === true) {
        try {
        response =  await postHostelData("account/create-guest", {
            phone: phoneNumber,
            name: name,
            hostel_id: hostel_id,
            user_id: userId,
            bill_clear: false,
            guest_type: "Walk-in Guest",
            has_gst: false,
            nationality: "",
            location: "",
            check_out_date: checkOutDate,
            check_in_date: checkInDate,
          });

          setGuestId(response.data.id);
          localStorage.setItem("guestId" , response.data.id);
          localStorage.setItem("TOKEN" , response.data.auth_token);
          const loginTime = new Date().getTime(); // Current time in milliseconds
          localStorage.setItem("loginTime", loginTime); // Store login time
          // Update authentication state
        // onLoginSuccess();
          localStorage.setItem("guestName" , response.data.name);
          localStorage.setItem("guestPhone" , phoneNumber);
          localStorage.setItem("guestStatus" , "Walk-in Guest");
          console.log(response.guest_type);

          guestFolioResponse = await postHostelData("account/create-guest-folio", {
            guest_id: response.data.id,
            hostel_id: hostel_id,
            user_id: userId,
          });
          console.log(guestFolioResponse);
          setGuestFolioId(guestFolioResponse.data.id);
          localStorage.setItem("guestFolioId" , guestFolioResponse.data.id);

          updateGuest = await putHostelData(`account/guest/${response.data.id}` , {
            current_folio_id: guestFolioResponse.data.id,
          })

          console.log(updateGuest);

          // navigate("/", { state: { hostel_id, hostel_name , userId , guestId , guestFolioId , categoryId} });
          // Navigate to the main menu path with hostel parameters
      navigateWithHostelParams(navigate, "/food-menu", {
        hostel_name,
        hostel_id,
        state: { userId, guestId: response.data.id, guestFolioId: guestFolioResponse.data.id, categoryId },
      });
        } catch (error) {
          console.error("Error posting guest data:", error);
        } finally{
          setSubmitLoading(false);
        }
      } else {
        console.log("old guest:",);
        // Normal navigation with the existing guest data
        // navigate("/", { state: { hostel_id, hostel_name , userId , guestId , guestFolioId , categoryId } });
          // Normal navigation if existing guest data is used
    navigateWithHostelParams(navigate, "/food-menu", {
      hostel_name,
      hostel_id,
      state: { userId, guestId, guestFolioId, categoryId },
    });
        setSubmitLoading(false);
      }
  }

  useEffect(() => {
    console.log("Hostel Name:", hostel_name);
    console.log("Hostel ID:", hostel_id);
     fetchHostelData();
  }, [hostel_name, hostel_id]);

  return (
    <>
     <Snackbar
        open={alertInfo.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleAlertClose} 
          severity={alertInfo.severity}
          sx={{ width: '100%' }}
        >
          {alertInfo.message}
        </Alert>
      </Snackbar>
    <Dialog
      open={open}
      // disableEscapeKeyDown={true}
      // disableBackdropClick={true}
      // disableEscapeKeyDown={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
    
      PaperProps={{
        style: {
          borderRadius: 20,
          padding: "20px",
          width: "90%",
          maxWidth: "400px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold"  }}>
        {hostel_name}
      </DialogTitle>
      <Box sx={{ borderBottom: '1px solid #E5E7EB', margin: '3px 0' }} />
      <DialogContent>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", marginBottom: "16px" , fontWeight: "bold" , color: "#E7319DFF"  }}
        >
          Welcome Guest
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          Phone Number
        </Typography>
        <TextField
          fullWidth
          placeholder="Enter 10 digit mobile number"
          variant="outlined"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          sx={{ marginTop: "8px", marginBottom: "24px" }}
          inputProps={{ maxLength: 10 }}
          InputProps={{
            sx: { fontSize: 16 }, // Adjust the size for the input text
          }}
          InputLabelProps={{
            sx: { fontSize: 16 }, // Adjust the size for the placeholder text
          }}
        />
        {/* body2 size = 14px  */}
        {loading && (
          <Typography variant="body2" sx={{ fontWeight: "bold", color: "blue" }}>
            Fetching Guest Details...
          </Typography>
        )}
        {/* {!loading && nameNotFound && (
          <Typography variant="body2" sx={{ fontWeight: "bold", color: "red" }}>
            Name not found
          </Typography>
        )} */}
        {!loading && isPhoneNumberValid  && (
          <>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Name
            </Typography>
            <TextField
              fullWidth
              placeholder="Please type here"
              variant="outlined"
              value={name}
              onChange={handleNameChange}
              sx={{ marginTop: "8px", marginBottom: "24px" }}
              InputProps={{
                sx: { fontSize: 16 }, // Adjust the size for the input text
              }}
              InputLabelProps={{
                sx: { fontSize: 16 }, // Adjust the size for the placeholder text
              }}
            />
          </>
        )}
        <Box sx={{ borderBottom: '1px solid #E5E7EB', margin: '16px 0' }} />
        <Typography variant="body2" sx={{ fontWeight: "bold", color: 'red' , marginTop:2 }}>
          Note:
        </Typography>
        <Typography variant="body2" sx={{ color: 'red' , fontSize:12.5 }}>
          1. If you are traveling in a group - Please use a single mobile number for all orders.
        </Typography>
        {/* <br/> */}
        <Typography variant="body2" sx={{ color: 'red' ,  fontSize:12.5 , paddingTop:0.6}}>
          2. If you are staying at the property - Please use the mobile number as per property records for all orders.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center"  , paddingTop:0.5}}>
        <Grid container justifyContent="center">
          <Grid
            item
            sx={{ width: "90%", justifyContent: "center", display: "flex" }}
          >
            <Button
              variant="contained"
              disabled={!isFormValid || submitLoading}
              onClick={handleSubmit} 
              fullWidth
              sx={{
                // backgroundColor: isFormValid ? "#4CAF50" : "#E0E0E0",
                // color: isFormValid ? "#FFFFFF" : "#9E9E9E",
                backgroundColor: isFormValid && !submitLoading ? "#4CAF50" : "#E0E0E0",
                color: isFormValid && !submitLoading ? "#FFFFFF" : "#9E9E9E",
                marginBottom: "16px",
                fontWeight:'bold'
              }}
            >
               {submitLoading ? <CircularProgress size={24} sx={{ color: '#FFFFFF' }} /> : "Confirm"}
            </Button>
          </Grid>
          <Grid
            item
            sx={{ width: "90%", justifyContent: "center", display: "flex" }}
          >
            <Button
              variant="outlined"
              fullWidth
              sx={{
                borderColor: "#2FA551",
                color: "#2FA551",
                fontWeight:'bold'
              }}
              // onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <Typography
        variant="caption"
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "end",
          marginTop: "16px",
          color: "#9E9E9E",
        }}
      >
        Powered By:{" "}
        <img
          alt="logo"
          style={{ marginLeft: 4 }}
          src={Logo}
        />
      </Typography>
    </Dialog>
    </>
  );
};

export default Login;
