// import { getUrl } from '@aws-amplify/storage';

// export const getFile = async (key, grpUserId) => {
//     if (!key || key.trim() === "") {
//       return null;
//     }
  
//     // Check if the key contains 'googleusercontent', return key directly
//     if (key.includes('googleusercontent')) {
//       return key;
//     } else {
//       try {
//         // Fetch the signed URL from S3 using Amplify Storage
//         const result = await getUrl(
//           `public${encodeURIComponent("")}/${key}`,
//           console.log(`public${encodeURIComponent("")}/${key}`),
//           {
//             level: 'public', // Adjust level (public, protected, private) as per your configuration
//             expires: 604800, // Expiration in seconds (7 days = 604800 seconds)
//             useAccelerateEndpoint: true, // Optional: to use S3 accelerate endpoint
//           }
//         );
//         console.log(result);
//         return result; // This will be the signed URL
//       } catch (error) {
//         console.error("Error getting file:", error);
//         return null;
//       }
//     }
//   };


import { getUrl } from '@aws-amplify/storage';

export const getFile = async (key, grpUserId) => {
   console.log(grpUserId);
  if (!key || key.trim() === "") {
    return null;
  }

  // Check if the key contains 'googleusercontent', return key directly
  if (key.includes('googleusercontent')) {
    return key;
  } else {
    try {
      // Fetch the signed URL from S3 using Amplify Storage
      const result = await getUrl({
        key: `${grpUserId}/${key}`,
        options: {
          // accessLevel: 'public', // Changed from 'level' to 'accessLevel'
          accessLevel: `public`, // Changed from 'level' to 'accessLevel'
          expires: 604800, // Expiration in seconds (7 days = 604800 seconds)
          useAccelerateEndpoint: true, // Optional: to use S3 accelerate endpoint
        }
      });
      console.log(result);
      return result.url; // Return the URL from the result object
    } catch (error) {
      console.error("Error getting file:", error);
      return null;
    }
  }
};