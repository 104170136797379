// Helper function to navigate with fixed hostel_name and hostel_id
// export const navigateWithHostelParams = (navigate, path, options = {}) => {
//   console.log(path);
//     // First check for hostel_name and hostel_id in localStorage
//    const hostel_name = options.hostel_name || localStorage.getItem('hostel_name');
//   const hostel_id = options.hostel_id || localStorage.getItem('hostel_id');

//   if (!hostel_name || !hostel_id) {
//     console.error("Hostel name or ID not found in options or localStorage.");
//     return;
//   }
//     navigate(`/hostel_name/${hostel_name}/hostel_id/${hostel_id}${path}`, 
//       { state: { ...options.state } });
//   };




  export const navigateWithHostelParams = (navigate, path, options = {}) => {
    const { hostel_name, hostel_id, state = {}, replace = false } = options;
    
    const finalHostelName = hostel_name || localStorage.getItem('hostel_name');
    const finalHostelId = hostel_id || localStorage.getItem('hostel_id');
    
    if (!finalHostelName || !finalHostelId) {
      console.error('Missing hostel parameters');
      navigate('/login', { replace: true });
      return;
    }
  
    const fullPath = `/hostel_name/${finalHostelName}/hostel_id/${finalHostelId}${path}`;
    navigate(fullPath, { state, replace });
  };
  