import React from 'react';
import LoaderGif from "../images/loader/loading.gif";
import { Box } from '@mui/material';

const FoodLoader = ({ loading }) => {
  if (!loading) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Optional overlay color
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000, // Ensure it appears on top
      }}
    >
      <img src={LoaderGif} alt="Loading..." style={{ width: '80px', height: '80px' }} />
    </Box>
  );
};

export default FoodLoader;
