import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import './App.css';
import Menu from './screens/Menu';
import Footer from './components/Footer';
import CategoryList from './screens/CategoryList';
import FoodItemList from './screens/FoodItemList';
import Login from './screens/Login';
import OrderSummary from './components/OrderSummary';
import CheckOut from './screens/CheckOut';
import OrderHistory from './screens/OrderHistory';
import OHSM from './screens/OHSM';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const TOKEN_EXPIRY_TIME = 15 * 60 * 1000; // 15 minutes in milliseconds


// Protected Route Component with redirect to food menu
const ProtectedRoute = ({ children }) => {
  console.log("ProtectedRoute");
  const token = localStorage.getItem('TOKEN');
  const loginTime = localStorage.getItem('loginTime');
  const storedHostelName = localStorage.getItem('hostel_name');
  const storedHostelId = localStorage.getItem('hostel_id');
  const { hostel_name, hostel_id } = useParams();
  const hostelPath = `/hostel_name/${hostel_name}/hostel_id/${hostel_id}`;

  if (!token || !loginTime || new Date().getTime() - loginTime > TOKEN_EXPIRY_TIME) {
    localStorage.removeItem('TOKEN');
    localStorage.removeItem('loginTime');
    return <Navigate to={`${hostelPath}/login`} replace />;
  }

   // Check if hostel name or hostel ID mismatches
   if (storedHostelName !== hostel_name || storedHostelId !== hostel_id) {
    console.log('Hostel name or hostel ID mismatch');
    localStorage.removeItem('TOKEN');
    localStorage.removeItem('loginTime');
    return <Navigate to={`${hostelPath}/login`} replace />;
  }

  return children;
};

// Redirect component for handling default routes
const DefaultRedirect = () => {
  const token = localStorage.getItem('TOKEN');
  const hostel_name = localStorage.getItem('hostel_name');
  const hostel_id = localStorage.getItem('hostel_id');

  if (token && hostel_name && hostel_id) {
    return <Navigate to={`/hostel_name/${hostel_name}/hostel_id/${hostel_id}/food-menu`} replace />;
  }
  return <OHSM />;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('TOKEN');
    setIsAuthenticated(!!token);
  }, []);

  // Helper function to handle login state
  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Base route - redirect to OHSM if no hostel info */}
          <Route 
            path="/" 
            element={
              (() => {
                const hostel_name = localStorage.getItem('hostel_name');
                const hostel_id = localStorage.getItem('hostel_id');
                return hostel_name && hostel_id ? (
                  <Navigate to={`/hostel_name/${hostel_name}/hostel_id/${hostel_id}/food-menu`} replace />
                ) : (
                  <OHSM />
                );
              })()
            } 
          />

          {/* Hostel-specific routes */}
          <Route path="hostel_name/:hostel_name/hostel_id/:hostel_id">
            {/* Protected Routes */}
            <Route
              path="food-menu"
              element={
                <ProtectedRoute>
                  <Menu />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="order-summary"
              element={
                <ProtectedRoute>
                  <OrderSummary />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="check-out"
              element={
                <ProtectedRoute>
                  <CheckOut />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="order-history"
              element={
                <ProtectedRoute>
                  <OrderHistory />
                </ProtectedRoute>
              }
            />

            {/* Public Routes */}
            <Route 
              path="login" 
              element={
                isAuthenticated ? (
                  <Navigate to="../food-menu" replace />
                ) : (
                  <Login onLoginSuccess={handleLogin} />
                )
              } 
            />
            
            <Route path="categories/fnb" element={
              <ProtectedRoute>
              <CategoryList type="fnb" />
              </ProtectedRoute>
              } />
            <Route path="categories/exp" element={
              <ProtectedRoute>
              <CategoryList type="exp" />
              </ProtectedRoute>
              } />
            <Route path="food-item" element={
              <ProtectedRoute>
              <FoodItemList />
              </ProtectedRoute>
              } />
          </Route>

          {/* Catch-all route
          <Route path="*" element={<OHSM />} /> */}

           {/* Catch-all route - redirects to food menu if authenticated, otherwise OHSM */}
          <Route path="*" element={<DefaultRedirect />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;