import { configureStore, createSlice } from '@reduxjs/toolkit';

// Create a slice for food details
const foodSlice = createSlice({
  name: 'food',
  initialState: {
    foodDetails: null,
    orderItems: [],
  },
  reducers: {
    setFoodDetails: (state, action) => {
      state.foodDetails = action.payload;
    },
    addOrderItem: (state, action) => {
      console.log(action.payload);
      state.orderItems.push(action.payload);
    },
    updateOrder: (state, action) => {
        const index = state.orderItems.findIndex(item => item.id === action.payload.id); // Find the item by id
        if (index !== -1) {
            state.orderItems = state.orderItems.map((item, i) =>
                i === index ? { ...item, ...action.payload } : item // Update only the specific item
              );
        }
      },
    // updateOrderItemCount: (state, action) => {
    //     const index = state.orderItems.findIndex(item => item.id === action.payload.id); // Assuming each item has a unique id
    //     if (index !== -1) {
    //       state.orderItems[index].count = action.payload.count;
    //     }
    //   },
    updateOrderItemCount: (state, action) => {
      const { id, addOns, count } = action.payload;
    
      // Find the item by id and addOns
      const index = state.orderItems.findIndex(
        (item) => 
          item.id === id &&
          JSON.stringify(item.addOns) === JSON.stringify(addOns) // Compare add-ons exactly
      );
    
      if (index !== -1) {
        // Update the count for the matched item
        state.orderItems[index].count = count;
      }
    },

    incrementOrderItem: (state, action) => {
      const item = action.payload;
      const existingItem = state.orderItems?.find(i => i.id === item.id);
      if (existingItem) {
        existingItem.count += 1;
      }
    },

    decrementOrderItem: (state, action) => {
      const item = action.payload;
      const existingItemIndex = state.orderItems.findIndex(
        (i) => i.id === item.id && JSON.stringify(i.addOns) === JSON.stringify(item.addOns)
      );
    
      if (existingItemIndex !== -1) {
        if (state.orderItems[existingItemIndex].count > 1) {
          // Decrement count if it’s above 1
          state.orderItems[existingItemIndex].count -= 1;
        } else {
          // Remove the item if the count is 1
          state.orderItems.splice(existingItemIndex, 1);
        }
      }
    },
    
    
    // removeOrderItem: (state, action) => {
    //   state.orderItems = state.orderItems.filter(item => item.id !== action.payload.id);
    // },
    removeOrderItem: (state, action) => {
      state.orderItems = state.orderItems.filter(item => 
        !(item.id === action.payload.id && 
          JSON.stringify(item.addOns) === JSON.stringify(action.payload.addOns))
      );
    },
    setCategories: (state, action) => {
      state.categories = action.payload; // Set categories in state
    },
    clearOrderItems: (state) => {
      state.orderItems = []; // Reset to an empty array
    },
  },
});

// Extract the actions and reducer
export const { setFoodDetails, addOrderItem, updateOrderItemCount , removeOrderItem , updateOrder , setCategories , clearOrderItems , incrementOrderItem , decrementOrderItem } = foodSlice.actions;
const foodReducer = foodSlice.reducer;

// Create the Redux store
const store = configureStore({
  reducer: {
    food: foodReducer,
  },
});

export default store;
