import React, { useEffect, useState , useCallback } from 'react';
import { AppBar, Box, Button, Typography, Avatar, Grid, CircularProgress, IconButton  } from '@mui/material';
import expIcon from "../images/icons/exp_icon.svg";
import fnbIcon from "../images/icons/fnb_icon.svg";
import { useNavigate , useLocation, useParams } from 'react-router-dom';
import rightIcon from "../images/icons/rightIcon.svg";
import { useDispatch, useSelector } from 'react-redux';
import { getHostelData } from '../api/services/FetchNodeService';
import { clearOrderItems, setCategories } from '../Redux/store';
import { Logout as LogoutIcon, History as HistoryIcon } from '@mui/icons-material';
import { navigateWithHostelParams } from '../components/NavigateWithHostelParams';

// const ONE_HOUR = 60 * 60 * 1000; // 1 hour in milliseconds

const FIFTEEN_MINUTES = 900000; // 15 minutes in milliseconds



const CategoryButton = ({ icon, label, parentSubCat }) => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Get the dispatch function

  const orderItems = useSelector((state) => state.food.orderItems);

  const [loading, setLoading] = useState(false); // Loading state

  const handleClick = async () => {
   
  

    // dispatch(clearOrderItems()); // Clear the order items
    setLoading(true); // Start loading
    try {
      const hostel_id = localStorage.getItem('hostel_id'); // Fetch hostel_id from local storage
      const hostelName = localStorage.getItem('hostel_name');
      if (hostel_id) {
        const response = await getHostelData(`account/sub-category/hostel/${hostel_id}/parent_sub_cat/${parentSubCat}` , navigate);
        // Handle the response data as needed
        console.log(response.data);
        const filteredCategories = response.data.filter(category => category.total_inventory > 0);
        console.log(filteredCategories);

     // Dispatch the action to store categories in Redux
       dispatch(setCategories(filteredCategories));
       const categories = filteredCategories;
        // Navigate to the specified route after fetching data
        // navigate(`/categories/${parentSubCat}` , { state: {  label ,categories  } });

        navigateWithHostelParams(navigate, `/categories/${parentSubCat}` , {    hostelName,
          hostel_id, state:{label ,categories}  });
          localStorage.setItem('LABEL' , label);
          localStorage.setItem('parentSubCat' , parentSubCat);

      } else {
        console.error('Hostel ID not found in local storage.');
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
    finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Button
      variant="contained"
      // startIcon={icon}
      startIcon={loading ? <CircularProgress size={24} /> : icon}
      onClick={handleClick} // Trigger the API call and navigation on click
      disabled={loading} // Disable button while loading
      sx={{
        width: '100%',
        justifyContent: 'flex-start',
        padding: '16px',
        marginBottom: '16px',
        backgroundColor: '#fff',
        color: '#000',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: '16px'
      }}
    >
      <Grid container  alignItems="center">
        <Grid item>
          {label} 
        </Grid>
        <Grid item sx={{marginLeft:1 , marginBottom:'-4px'}}>
          {/* <img src={rightIcon} alt="Right Icon" /> */}
          {!loading && <img src={rightIcon} alt="Right Icon" />}
        </Grid>
      </Grid>
    </Button>
  );
};

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hostelName , setHostelName] = useState(
      localStorage.getItem('hostel_name')
  );
  const [sessionExpiration, setSessionExpiration] = useState(null);
  const hostelId = localStorage.getItem('hostel_id');
  // const { HostelId } = useParams();
  const {hostel_id , hostel_name , userId , guestId}  = location.state || {}; // Retrieve categories data from state

 
    
  








  // Enhanced logout handler
  const handleLogout = useCallback((isSessionExpired = false) => {
    try {
      // Store hostel info before clearing
      const hostelId = localStorage.getItem('hostel_id');
      const hostelName = localStorage.getItem('hostel_name');

      // Clear all session and user-specific data
      const itemsToKeep = ['hostel_id', 'hostel_name'];
      Object.keys(localStorage).forEach(key => {
        if (!itemsToKeep.includes(key)) {
          localStorage.removeItem(key);
        }
      });

      // Clear any Redux state if needed
      // dispatch(clearUserData()); // Add your Redux clear action if needed

      // Force a state update
      window.dispatchEvent(new Event('storage'));

      // Navigate to login with preserved hostel parameters
      navigateWithHostelParams(navigate, '/login', {
        hostel_id: hostelId,
        hostel_name: hostelName,
        state: {
          loggedOut: true,
          sessionExpired: Boolean(isSessionExpired)
        },
        replace: true // Important: prevent back navigation
      });
       // Refresh the page after fallback navigation
    window.location.reload();

    } catch (error) {
      console.error('Logout error:', error);
      // Fallback navigation
      const hostelId = localStorage.getItem('hostel_id');
      const hostelName = localStorage.getItem('hostel_name');
      navigate(`/hostel_name/${hostelName}/hostel_id/${hostelId}/login`, { 
        replace: true,
        state: { error: true }
      });
       // Refresh the page after fallback navigation
    window.location.reload();
    }
  }, [navigate]);

 

 


 

  // Periodic session check


  const handleOrderHistory = useCallback(() => {
    navigateWithHostelParams(navigate, '/order-history', {
      state: { fromMenu: true }
    });
  }, [navigate]);


 

  // Usage in your app:
  
  return (
    <Box sx={{ padding: '24px', backgroundColor: '#f5f5f5', height: '100vh' }}>
      {/* <AppBar position="static" color="transparent" elevation={0}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight:'bold'}}>
             {hostel_name}
            </Typography>
          </Grid>
        </Grid>
      </AppBar> */}
       <AppBar position="static" color="transparent" elevation={0}>
      <Grid container alignItems="center">
        {/* Top Left - Order History Button */}
        <Grid item xs={4} display="flex" justifyContent="flex-start">
          <Button
            startIcon={<HistoryIcon />}
            onClick={handleOrderHistory}
      
            sx={{ fontWeight: 'bold', color: 'primary.main' }}
          >
            Order History
          </Button>
        </Grid>

        {/* Center - Hostel Name */}
        <Grid item xs={4} display="flex" justifyContent="center">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {hostelName } 
          </Typography>
        </Grid>

        {/* Top Right - Logout Button */}
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <IconButton onClick={handleLogout} color="primary">
            <LogoutIcon />
          </IconButton>
        </Grid>
      </Grid>
    </AppBar>
      <Box sx={{ borderBottom: '1px solid #E5E7EB', margin: '16px 0' }} />
      <Box sx={{ textAlign: 'left', marginTop: 3 }}>
        <Typography sx={{  fontWeight: 'bold' }}>
          Dear Guest,
        </Typography>
        <Typography variant='subtitle1' sx={{  fontWeight: 500 , fontSize:14 }}>
          Explore our F&B and Experiences & Merchandise menus!
        </Typography>
      </Box>
      <Button
        variant="outlined"
        sx={{
          borderRadius: '24px',
          padding: '4px 16px',
          textTransform: 'none',
          fontWeight: 'bold',
          color: '#d63384',
          borderColor: '#d63384',
          marginBottom: '16px',
          marginTop: '16px',
        }}
      >
        Menu
      </Button>
      <CategoryButton
        icon={<Avatar src={fnbIcon} variant="rounded" />}
        parentSubCat="fnb" // Pass 'fnb' as the parent_sub_cat
        label="Food & Beverage"
      />
      <CategoryButton
        icon={<Avatar src={expIcon} variant="rounded" />}
        parentSubCat="exp" // Pass 'exp' as the parent_sub_cat
        label="Experiences & Merchandise"
      />
    </Box>
  );
};

export default Menu;

