// const { default: axios } = require("axios");
import axios from "axios"
import { navigateWithHostelParams } from "../../components/NavigateWithHostelParams";


// var authURL='https://sfmuibdpcvny7arrxzc5fhelxi0fekxb.lambda-url.ap-south-1.on.aws/v1/api'
var authURL='https://3buuksiaznzpbuebajfqtaj72a0ghnov.lambda-url.ap-south-1.on.aws/v1/api'
// var hostelURL='https://pt4vd4o3fpadturtnrlftoklsa0ajzda.lambda-url.ap-south-1.on.aws/v1/api'
var hostelURL='https://limlicvyug6nfj3m4ohtttn6iy0hdvup.lambda-url.ap-south-1.on.aws/v1/api'

const getData= async (url)=>{

    try{
        // alert(localStorage.getItem("TOKEN"))
          let headers = {}
          if(localStorage.getItem("TOKEN")){
            headers = {headers:{Authorization:localStorage.getItem("TOKEN")}}
          }

       var response = await axios.get(`${authURL}/${url}` , headers)
       var result = await response.data
       return (result)
    }catch(e){
        console.log(e)
        return (null)
        
    }
}


const postData= async (url,body)=>{
    try{
            //  alert(localStorage.getItem("TOKEN"))
        let headers = {}
        if(localStorage.getItem("TOKEN")){
          headers = {headers:{Authorization:localStorage.getItem("TOKEN")}}
        }

       var response = await axios.post(`${authURL}/${url}`,body , headers)
       var result = await response.data
       return (result)
    }catch(e){
        console.log(e)
        return (null)
        
    }
}



const putData = async (url, body, token) => {
    try {
      let headers = {};
      if (token) {
        headers = { headers: { Authorization: token } };
      }
  
      const response = await axios.put(`${authURL}/${url}`, body, headers);
      const result = await response.data;
      return result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
const putDataWithoutToken = async (url, body) => {
    try {
      const response = await axios.put(`${authURL}/${url}`, body);
      const result = await response.data;
      return result.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

const putHostelData = async (url, body, token) => {
    try {
      let headers = {};
      if (token) {
        headers = { headers: { Authorization: token } };
      }
  
      const response = await axios.put(`${hostelURL}/${url}`, body, headers);
      const result = await response.data;
      return result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
const postHostelData = async (url, body, token) => {
    try {
      // let headers = {};
      // if (token) {
      //   headers = { headers: { 
      //     // 'Content-Type': 'multipart/form-data',
      //     Authorization: token } };
      // }

      let headers = {}
      if(localStorage.getItem("TOKEN")){
        headers = {headers:{Authorization:localStorage.getItem("TOKEN")}}
      }
      console.log(headers);
  
      const response = await axios.post(`${hostelURL}/${url}`, body, headers);
      const result = await response.data;
      return result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const getHostelData = async (url,  navigate) => {

    try {
      // let headers = {};
      // if (token) {
      //   headers = { headers: { Authorization: token } };
      // }

       // alert(localStorage.getItem("TOKEN"))
       let headers = {}
       if(localStorage.getItem("TOKEN")){
         headers = {headers:{Authorization:localStorage.getItem("TOKEN")}}
       }

  console.log(headers);
      const response = await axios.get(`${hostelURL}/${url}`, headers);
      const result = await response.data;
      return result;
    } catch (e) {
      // console.log(e);
      // return null;

      if (e.response && e.response.status === 403) {
        console.log("403 Forbidden");
        // Redirect to login page on 403 Forbidden
        // navigate("/login");
        navigateWithHostelParams(
          navigate,
          "/login"
        )
      } else {
        console.log(e);
      }
      return null;
    }
  };




  const deleteData = async (url, token) => {
    try {
      let headers = {};
      if (token) {
        headers = { headers: { Authorization: token } };
      }
  
      const response = await axios.delete(`${hostelURL}/${url}`, headers);
      const result = await response.data;
      return result;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  
 


export {authURL, hostelURL ,getData,postData , putData , putHostelData , getHostelData , deleteData , putDataWithoutToken , postHostelData  }